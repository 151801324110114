import React, { useEffect, useState } from 'react'
import { color } from '@configs/utilities'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import { injectIntl } from 'gatsby-plugin-react-intl'
import playIcon from '@assets/images/icons/circle-play.png'
import ModalVideo from 'react-modal-video'
import getGlobalDocument from '@configs/getGlobalDocument'

const VideoTourButton = ({ intl, videoId, smfontsize = '16px', mdfontsize = '16px' }) => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    let documentStyle = getGlobalDocument().getElementsByTagName('html')[0]

    isOpen ? (documentStyle.style.overflow = 'hidden') : (documentStyle.style.overflow = 'auto')
  }, [isOpen])

  function onClick(e) {
    e.preventDefault()
    setOpen(true)
  }

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        muted={true}
        onClose={() => setOpen(false)}
      />
      <ButtonLink
        buttonBackground={color.white}
        buttonColor={color.font}
        border={`inset 0 0 0 2px ${color.silver}`}
        buttonText={intl.formatMessage({ id: 'home.hero.videoTour' })}
        smfontsize={smfontsize}
        mdfontsize={mdfontsize}
        icon={<img src={playIcon} alt="play-icon" width={18} />}
        onClick={(e) => onClick(e)}
      />
    </>
  )
}

export default injectIntl(VideoTourButton)
