import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import HeroCard from '@components/pages/home/heroCard/HeroCard'
import capterraImage from '@assets/images/home/heroCards/capterra.webp'
import forbesAdvisorImage from '@assets/images/home/heroCards/forbes-advisor.webp'
import g2Image from '@assets/images/home/heroCards/g2.webp'
import { breakpoint } from '@configs/utilities'
const Excellent02 = () => {
  return (
    <Wrapper>
      <a href="https://www.capterra.com/p/204342/IdeaBuddy/reviews/" target={'_blank'} rel="noreferrer">
        <HeroCard image={capterraImage} alt={'capterra'} />
      </a>
      <a
        href="https://www.forbes.com/advisor/business/software/best-business-plan-software/"
        target={'_blank'}
        rel="noreferrer"
      >
        <HeroCard image={forbesAdvisorImage} alt={'forbes-advisor'} smWidth={200} mdWidth={220} />
      </a>
      <a href="https://www.g2.com/products/ideabuddy/reviews" target={'_blank'} rel="noreferrer">
        <HeroCard image={g2Image} alt={'g2'} />
      </a>
    </Wrapper>
  )
}

export default injectIntl(Excellent02)

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  max-width: 600px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
  }

  img {
    width: 100%;
    max-width: 180px;
    @media screen and (min-width: ${breakpoint.md}) {
    }
  }
`
